import * as React from "react";
import { Helmet } from "react-helmet";

// styles
const pageStyles = {
  color: "#232129",
  padding: "48px 16px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  textAlign: "center",
};

const imgStyles = {
  maxWidth: "100%",
  height: "auto",
};

const articleStyles = {
  maxWidth: "800px",
  textAlign: "left",
  margin: "48px auto 0",
  lineHeight: "1.6",
};

// markup
const PrivacyPolicyPage = () => {
  return (
    <main style={pageStyles}>
      <Helmet>
        <title>Privacy Policy</title>
      </Helmet>
      <img
        src="/static/martin-bex.jpg"
        width="240"
        height="240"
        style={imgStyles}
      />
      <article style={articleStyles}>
        <h1>Martin and Bex Soundboard Privacy Policy</h1>
        <div>
          <p>Effective date: 04 December 2020</p>
          <p>
            Martin and Bex ("us", "we", or "our") operates the{" "}
            <a href="https://martinandbex.com">Martin and Bex</a> soundboard app
            (the "App").
          </p>
          <p>
            This page informs you of our policies regarding the collection, use,
            and disclosure of personal data when you use our App and the choices
            you have associated with that data.
          </p>
          <p>
            We use your data to provide and improve the App. By using the App,
            you agree to the collection and use of information in accordance
            with this policy. Unless otherwise defined in this Privacy Policy,
            terms used in this Privacy Policy have the same meanings as in our
            Terms and Conditions, accessible from https://martinandbex.com.
          </p>
          <h3>Third Party Consent</h3>
          <p>
            All third-party product names, logos, and brands are property of
            their respective owners. All third-party company, product and
            service names used in this website are for identification purposes
            only. Use of these names, logos, and brands does not imply copyright
            ownership.
          </p>
          <h3>Information Collection and Use</h3>
          <p>
            We collect several different types of information for various
            purposes to provide and improve our App to you.
          </p>
          <h4>Personal Data</h4>
          <p>
            While using our App, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you ("Personal Data"). Personally identifiable information
            may include, but is not limited to:
          </p>
          <ul>
            <li>Cookies &amp; Usage Data</li>
          </ul>
          <h4>Usage Data</h4>
          <p>
            We may also collect information how the App is accessed and used
            ("Usage Data"). This Usage Data may include information such as your
            computer's Internet Protocol address (e.g. IP address), browser
            type, browser version, the pages of our App that you visit, the time
            and date of your visit, the time spent on those pages, unique device
            identifiers and other diagnostic data.
          </p>
          <h4>Tracking &amp; Cookies Data</h4>
          <p>
            We use cookies and similar tracking technologies to track the
            activity on our App and hold certain information.
          </p>
          <p>
            Cookies are files with small amount of data which may include an
            anonymous unique identifier. Cookies are sent to the App from a
            website and stored on your device. Tracking technologies also used
            are beacons, tags, and scripts to collect and track information and
            to improve and analyze our App.
          </p>
          <p>
            You can instruct the App to refuse all cookies or to indicate when a
            cookie is being sent. However, if you do not accept cookies, you may
            not be able to use some portions of our App.
          </p>
          <p>Examples of Cookies we use:</p>
          <ul>
            <li>Session Cookie: We use Session Cookies to operate our App.</li>
            <li>
              Security Cookie: We use Security Cookies for security purposes.
            </li>
            <li>
              Preference Cookie: We use Preference Cookies to remember your
              preferences and various settings.
            </li>
          </ul>
          <h3>Use of Data</h3>
          <p>Martin and Bex uses the collected data for various purposes:</p>
          <ul>
            <li>To provide and maintain the App</li>
            <li>To notify you about changes to our App</li>
            <li>
              To allow you to participate in interactive features of our App
              when you choose to do so
            </li>
            <li>To provide customer care and support</li>
            <li>
              To provide analysis or valuable information so that we can improve
              the App
            </li>
            <li>To monitor the usage of the App</li>
            <li>To detect, prevent and address technical issues</li>
          </ul>
          <h2>Transfer of data</h2>
          <p>
            Your information, including Personal Data, may be transferred to —
            and maintained on — computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may differ than those from your jurisdiction.
          </p>
          <p>
            If you are located outside United Kingdom and choose to provide
            information to us, please note that we transfer the data, including
            Personal Data, to United Kingdom and process it there.
          </p>
          <p>
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </p>
          <p>
            Martin and Bex will take all steps reasonably necessary to ensure
            that your data is treated securely and in accordance with this
            Privacy Policy and no transfer of your Personal Data will take place
            to an organization or a country unless there are adequate controls
            in place including the security of your data and other personal
            information.
          </p>
          <h2>Disclosure Of Data</h2>
          <h3>Legal Requirements</h3>
          <p>
            Martin and Bex may disclose your Personal Data in the good faith
            belief that such action is necessary to:
          </p>
          <ul>
            <li>To comply with a legal obligation</li>
            <li>
              To protect and defend the rights or property of Martin and Bex
            </li>
            <li>
              To prevent or investigate possible wrongdoing in connection with
              the App
            </li>
            <li>
              To protect the personal safety of users of the App or the public
            </li>
            <li>To protect against legal liability</li>
          </ul>
          <h2>Security Of Data</h2>
          <p>
            The security of your data is important to us, but remember that no
            method of transmission over the Internet, or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Data, we cannot guarantee
            its absolute security.
          </p>
          <h2>App Providers</h2>
          <p>
            We may employ third party companies and individuals to facilitate
            our App ("Service Providers"), to provide the App on our behalf, to
            perform App-related services or to assist us in analyzing how our
            App is used.
          </p>
          <p>
            These third parties have access to your Personal Data only to
            perform these tasks on our behalf and are obligated not to disclose
            or use it for any other purpose.
          </p>
          <h3>Analytics</h3>
          <p>
            We may use third-party App Providers to monitor and analyze the use
            of our App.
          </p>
          <ul>
            <li>
              Google Analytics: Google Analytics is a web analytics service
              offered by Google that tracks and reports website traffic. Google
              uses the data collected to track and monitor the use of our App.
              This data is shared with other Google services. Google may use the
              collected data to contextualize and personalize the ads of its own
              advertising network.
            </li>
          </ul>
          <h2>Links to other sites</h2>
          <p>
            Our App may contain links to other sites that are not operated by
            us. If you click on a third party link, you will be directed to that
            third party's site. We strongly advise you to review the Privacy
            Policy of every site you visit. We have no control over and assume
            no responsibility for the content, privacy policies or practices of
            any third party sites or services.
          </p>
          <h2>Children's Privacy</h2>
          <p>
            Our App does not address anyone under the age of 18 ("Children").
          </p>
          <p>
            We do not knowingly collect personally identifiable information from
            anyone under the age of 18. If you are a parent or guardian and you
            are aware that your Children has provided us with Personal Data,
            please contact us. If we become aware that we have collected
            Personal Data from children without verification of parental
            consent, we take steps to remove that information from our servers.
          </p>
          <h2>Changes To This Privacy Policy</h2>
          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>
          <p>
            We will let you know via email and/or a prominent notice on our App,
            prior to the change becoming effective and update the "effective
            date" at the top of this Privacy Policy.
          </p>
          <p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
          <h2>Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us: By email: contact@martinandbex.com
          </p>
        </div>
      </article>
    </main>
  );
};

export default PrivacyPolicyPage;
